<template lang="pug">
    div.d-flex.flex-column
      HeaderGrid(:name="name" :uid="uid" :dateRanger="dateRanger" :tvaModeButton="tvaModeButton" :addButton="addButton" :searchButton="searchButton" :tagguerButton="tagguerButton" :selectedRows="selectedRows" @onFilterTextBoxChanged="onFilterTextBoxChanged" @selectParamerterAction="selectParamerterAction" @clickAction="clickAction" :searchPlaceholder="searchPlaceholder" :archiveMode="archiveMode" :archive="archive" @refreshRow="refreshRow" :indexSearch="indexSearch")
      .d-flex.w-100.h-100(style="overflow: hidden;")
        .text-center.flex-center.w-100(v-if='isLoadingAnalyticsSettingsList || !headerLoaded || isLoadingAnalyticsSettingsList')
          .loading-bg-inner
            .loader
              .outer
              .middle
              .inner
          .mt-5
              br
              br
              br
              | Chargement de la liste des codes analytique..
        sync-tree-grid#overviewtreegrid(ref="overviewtreegrid" v-else :editSettings='editSettings' :parentIdMapping="parentIdMapping" :idMapping="idMapping" :hasChildMapping="hasChildMapping" :treeColumnIndex="treeColumnIndex" :allowGrouping="allowGrouping" :gridData="dataFiltered" :name="name" :headerData="headerData" @rowClicked="onRowClicked" @setSelectedRows="setSelectedRows" :tagguerButton="tagguerButton" @this.$refs.overviewtreegrid="this.$refs.overviewtreegrid" @deleteButtonClicked="deleteButtonClicked" @actionBegin="actionBegin" @archiveButtonClicked="archiveButtonClicked")
</template>

<script>
import { mapGetters, mapActions } from "vuex";

import { formatCurrency } from "@/types/api-orisis/library/FormatOperations.ts";
import { setColumnsByObject } from "@/types/api-orisis/library/ObjectOperations.ts";

var dayjs = require("dayjs");
var quarterOfYear = require("dayjs/plugin/quarterOfYear");
var utc = require("dayjs/plugin/utc");
var timezone = require("dayjs/plugin/timezone");
var advanced = require("dayjs/plugin/advancedFormat");
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.setDefault("Europe/Paris");
dayjs.extend(advanced);
dayjs.extend(quarterOfYear);

import SyncTreeGrid from "@/components/global/grid/TreeGrid.vue";
import HeaderGrid from "@/components/global/grid/HeaderGrid.vue";

export default {
  components: {
    SyncTreeGrid,
    HeaderGrid,
  },
  data() {
    return {
      indexSearch: 0,
      name: "analyticsSetting",
      uid: "analyticsSetting",
      archive: false,
      headerData: [],
      headerLoaded: false,
      tabActive: "all",
      selectedRows: [],
      dateRanger: false,
      tvaModeButton: false,
      addButton: true,
      searchButton: true,
      tagguerButton: false,
      archiveMode: false,
      searchPlaceholder: "Rechercher un code analytique",
      editSettings: {
        allowEditing: true,
        allowAdding: true,
        allowDeleting: true,
        mode: "Dialog",
        titleAdd: "Nouveau code analytique",
        titleEdit: "Modifier le code analytique",
        titleField: "analyticCode",
      },
      allowGrouping: false,
      parentIdMapping: "parentAnalyticsSettingId",
      idMapping: "id",
      hasChildMapping: "hasChild",
      treeColumnIndex: 1,
    };
  },
  computed: {
    ...mapGetters(["analyticsSettingsList", "isLoadingAnalyticsSettingsList"]),
    dataFiltered() {
      return this.$store.getters["analyticsSettingsList"].map((elem) => {
        return {
          ...elem,
          hasChild: elem.children?.length ? true : false,
        };
      });
    },
  },
  async created() {
    await this.getAnalyticsSettings({}).then(async (res) => {
      this.setHeaderData();
      if (res.length) {
        await this.setColumnsByObject(res[0], null, this);
        this.indexSearch++;
      }
      this.headerLoaded = true;
    });
  },
  methods: {
    ...mapActions([
      "getAnalyticsSettings",
      "createAnalyticsSetting",
      "updateAnalyticsSetting",
      "archiveAnalyticsSettings",
      "deleteAnalyticsSettings",
    ]),
    formatCurrency,
    setColumnsByObject,
    setHeaderData() {
      this.headerData = [
        {
          type: "string",
          field: "color",
          headerText: "Couleur",
          width: 60,
          maxWidth: 60,
          allowFiltering: true,
          allowSorting: true,
          showInColumnChooser: true,
          template: "colorTemplate",
          visible: true,
          unavailableExport: true,
          edit: { type: "colorpicker" },
        },
        {
          type: "string",
          field: "analyticCode",
          headerText: "Code analytique",
          width: 140,
          minWidth: 140,
          allowFiltering: true,
          allowSorting: true,
          showInColumnChooser: true,
          allowEditing: true,
          visible: true,
          validationRules: { required: true, minLength: 2 },
        },
        {
          type: "string",
          field: "label",
          headerText: "Libellé",
          width: 250,
          minWidth: 250,
          allowFiltering: true,
          allowSorting: true,
          showInColumnChooser: true,
          allowEditing: true,
          validationRules: { required: true, minLength: 2 },
          visible: true,
        },
        {
          type: "number",
          field: "parentAnalyticsSettingId",
          format: "formatNumber",
          headerText: "Code parent",
          width: 100,
          maxWidth: 100,
          allowFiltering: true,
          allowSorting: true,
          allowEditing: true,
          showInColumnChooser: true,
          visible: false,
          valueAccessor: "dropdown",
          edit: {
            source: this.dataFiltered,
            fields: {
              text: "analyticCode",
              value: "id",
              hasChildren: "hasChild",
              parentValue: "parentAnalyticsSettingId",
              expanded: "hasChild",
              dataSource: this.dataFiltered,
            },
            type: "dropdowntree",
          },
        },
      ];
    },
    async actionBegin(args) {
      if (args.requestType === "save" && args.action === "add") {
        await this.createAnalyticsSetting({
          analyticsSetting: {
            ...args.data,
            id: 0,
            parentAnalyticsSettingId: args.data.parentAnalyticsSettingId
              ? args.data.parentAnalyticsSettingId
              : 0,
          },
        });
      }

      if (args.requestType === "save" && args.action === "edit") {
        await this.updateAnalyticsSetting({ analyticsSetting: args.data });
      }
    },
    clickAction() {
      this.$refs.overviewtreegrid.$refs.overviewtreegrid.addRecord();
    },
    onRowClicked() {},
    async archiveButtonClicked(args) {
      await this.archiveAnalyticsSettings({
        analyticsSettingIds: [args.rowData.id],
      });
      this.$refs.overviewtreegrid.$refs.overviewtreegrid.closeEdit();
      this.$refs.overviewtreegrid.$refs.overviewtreegrid.refresh();
    },
    async deleteButtonClicked(args) {
      await this.deleteAnalyticsSettings({
        analyticsSettingIds: [args.rowData.id],
      });
      this.$refs.overviewtreegrid.$refs.overviewtreegrid.closeEdit();
      this.$refs.overviewtreegrid.$refs.overviewtreegrid.refresh();
    },
    onFilterTextBoxChanged(search) {
      this.$refs.overviewtreegrid.onFilterTextBoxChanged(search);
    },
    selectParamerterAction(args) {
      if (["csv", "xls", "print"].includes(args.item.type)) {
        this.$refs.overviewtreegrid.exportData(args.item.type);
      } else if (args.item.type == "archive") {
        this.archive = true;
      } else if (args.item.type == "current") {
        this.archive = false;
      } else {
        this.$refs.overviewtreegrid.$refs.overviewtreegrid.openColumnChooser();
      }
    },
    setSelectedRows(val) {
      this.selectedRows = val;
    },
    refreshRow(val) {
      this.$refs.overviewtreegrid.$refs.overviewtreegrid.setRowData(
        val.id,
        val
      );
    },
  },
};
</script>
